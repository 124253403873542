import { graphql } from 'gatsby'
import { Navbar } from '../Navbar'
import htmr from 'htmr'
import { Helmet, Link } from 'gatsby-plugin-react-i18next'
import '../../assets/OneLight.css'
import '../../assets/Post.css'
import { Footer } from '../Footer'
import { intlFormat } from 'date-fns'

const BlogPost = ({ data: { post } }) => {
  const parsedHtml = htmr(post.html)
  const { frontmatter } = post

  return (
    <>
      <Helmet>
        <title>{frontmatter.title}</title>
        <link
          rel='preload'
          href='/fonts/jetbrains-mono-regular.woff2'
          as='font'
          type='font/woff2'
          crossOrigin='anonymous'
        />
      </Helmet>
      <div className='flex flex-col min-h-screen  bg-neutral-100'>
        <Navbar />
        <div className='flex gap-2 flex-col justify-center py-4 max-w-screen-md container'>
          <h1 className=' py-4 text-2xl sm:text-3xl font-header font-extrabold text-gradient bg-gradient-to-r from-brand to-auxiliary'>
            {frontmatter.title}
          </h1>
          <h2 className='font-text'>{frontmatter.description}</h2>
          <div className='flex gap-4 mt-4 font-sub'>
            <h3>{intlFormat(
              new Date(frontmatter.date),
              {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              },
              { locale: frontmatter.lang })}
            </h3>
            <Link
              to='/'
              className='hover:border-neutral-300 border-b border-transparent'
            >
              <h3>{frontmatter.author}</h3>
            </Link>
          </div>
        </div>
        <main className='bg-white rounded-t-lg post-content container max-w-screen-md font-text py-4  flex-grow'>
          {parsedHtml}
        </main>
        <Footer />
      </div>
    </>
  )
}

export default BlogPost

export const query = graphql`
  query ($language: String!, $slug: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    post: markdownRemark(
      frontmatter: { lang: { eq: $language }, slug: { eq: $slug } }
    ) {
      html
      frontmatter {
        date
        slug
        title
        description
        author
        lang
      }
    }
  }
`
